import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'
import { GatsbyImage } from "gatsby-plugin-image";
const VisionComponent = (props) =>{
    const {visionTitle,visionDescription,VisionImage,isSalesForce} = props;
    return (
      <div className="vision-section">
        <div className="full-container">
          <div className="row">
            <div className="col-md-6 order-md-1 order-2 vision-section-col">
              <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone" />
                <h2 className="vision-section-title">{visionTitle}</h2>
              </div>
              <p
                className={`vision-section-description ${
                  isSalesForce ? "salesforce-description" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: visionDescription }}
              ></p>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <GatsbyImage
                alt="visionImage"
                image={VisionImage}
                className="vision-section-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
}
export default VisionComponent