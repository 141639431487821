import React from 'react';
import HistoryComponent from '../component/Company/historyComponent';
import JoinComponent from '../component/Company/joinComponent';
import MissionComponent from '../component/Company/missionComponent';
import VisionComponent from '../component/Company/visionComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import BlogHomeComponent from '../component/Blog/blogHomeComponent';
import CompanyImage from '../images/company_banner.png';
import Layout from '../component/layout';
const Company = props => {
  const {
    data: {
      wpPage: {
        seo,
        companyJoinUsSection: {
          companyJoinUsButton,
          companyJoinUsDescription,
          companyJoinUsTitle,
        },
        companyHistorySection: {
          companyHistoryDescription,
          companyHistoryTitle,
        },
        companyMissionSection: {
          companyMissionDescription,
          companyMissionTitle,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: companyMissionImage },
            },
          },
        },
        companyVisionSection: {
          companyVisionDescription,
          companyVisionTitle,
          companyVisionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: companyVisionImage },
            },
          },
        },
        homePageHeaderSection: { homeHeaderTitle, homeHeaderDescription },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <BlogHomeComponent
        blogTitle={homeHeaderTitle}
        blogDescription={homeHeaderDescription}
        ourWorkImage={CompanyImage}
      />
      <VisionComponent
        visionTitle={companyVisionTitle}
        visionDescription={companyVisionDescription}
        VisionImage={companyVisionImage}
      />
      <MissionComponent
        visionTitle={companyMissionTitle}
        missionDescription={companyMissionDescription}
        imageMission={companyMissionImage}
      />
      <HistoryComponent
        historyTitle={companyHistoryTitle}
        historyDescription={companyHistoryDescription}
      />
      <JoinComponent
        joinTitle={companyJoinUsTitle}
        joinDescription={companyJoinUsDescription}
        joinButton={companyJoinUsButton}
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "company-page" }) {
      seo {
        metaDesc
        title
      }
      companyJoinUsSection {
        companyJoinUsButton
        companyJoinUsDescription
        companyJoinUsTitle
      }
      companyHistorySection {
        companyHistoryDescription
        companyHistoryTitle
      }
      companyMissionSection {
        companyMissionDescription
        companyMissionTitle
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      companyVisionSection {
        companyVisionDescription
        companyVisionTitle
        companyVisionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default Company;
