import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
const MissionComponent = props => {
  const { visionTitle, missionDescription, imageMission } = props;
  return (
    <div className='mission-section'>
      <div className='full-container'>
        <div className='row'>
          <div className='col-md-6 order-md-1 order-2 mission-section-col'>
            <div className='mission-section-wrapper'>
              <div className='home-section-textIcone'>
                <TitleIcone className='home-section-titleIcone' />
                <h3 className='mission-section-title'>{visionTitle}</h3>
              </div>
              <p
                className='mission-section-description'
                dangerouslySetInnerHTML={{
                  __html: missionDescription?.replace(
                    'Motomtech',
                    'UpTech Sh.P.K'
                  ),
                }}
              ></p>
            </div>
          </div>
          <div className='col-md-6 order-md-2 order-1'>
            <GatsbyImage
              alt='missionImage'
              image={imageMission}
              className='mission-section-image'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MissionComponent;
