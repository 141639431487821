import { Link } from 'gatsby';
import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';

const JoinComponent = props => {
  const { joinTitle, joinDescription, joinButton } = props;
  return (
    <div className='join-section'>
      <div className='full-container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='home-section-textIcone'>
              <TitleIcone className='home-section-titleIcone' />
              <h3 className='join-section-title'>{joinTitle}</h3>
            </div>
            <p className='join-section-description'>
              {joinDescription.replaceAll('Motomtech', 'UpTech Sh.p.k')}
            </p>
          </div>
          <div className='col-md-4 join-section-col'>
            <Link to='/careers' className='join-section-button'>
              {joinButton}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JoinComponent;
