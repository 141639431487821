import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import HistoryImage from '../../images/company/history.png';
const HistoryComponent = props => {
  const { historyDescription, historyTitle } = props;
  return (
    <div className='history-section'>
      <div className='full-container'>
        <img src={HistoryImage} className='history-section-image' />
        <div className='history-section-wrapper'>
          <div className='home-section-textIcone'>
            <TitleIcone className='home-section-titleIcone' />
            <h2 className='history-section-title'>{historyTitle}</h2>
          </div>
          <p
            className='history-section-description'
            dangerouslySetInnerHTML={{
              __html: historyDescription
                ?.replaceAll('Motomtech’s', 'UpTech Sh.p.k')
                ?.replace('Motomtech', 'UpTech Sh.p.k'),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};
export default HistoryComponent;
